import React, { useRef, useState, useEffect } from 'react';
import Daily from '@daily-co/daily-js';
import "./VideoCall.scss";
import { createMeeting } from '../../services/meeting';
import { FaVideo, FaVideoSlash } from 'react-icons/fa';

const VideoCall = ({ onCallEnded, sessionId, username, userId }) => {
  const iframeRef = useRef(null);
  const [callFrame, setCallFrame] = useState(null);
  const [isOnCall, setIsOnCall] = useState(false);
  const [isCreatingRoom, setIsCreatingRoom] = useState(true);

  const getMeetingInfo = async () => {
    if (sessionId) {
      setIsCreatingRoom(true);
      await createMeeting(sessionId);
      setIsCreatingRoom(false);
    }
  };

  useEffect(() => {
    getMeetingInfo();
  }, [sessionId, username, userId]);

  const startCall = () => {
    if (iframeRef.current) {
      const frame = Daily.wrap(iframeRef.current); // Wrap the iframe
      setCallFrame(frame);

      // Join the call
      frame.join({
        url: `https://9mb.daily.co/${sessionId}`,
        dailyConfig: {
          showBranding: false, // Disable branding
        },
        startAudioOff: true,
        startVideoOff: true,
        userName: username || "Guest",
        userData: {
          userId: userId,
        },
      });

      // Listen to events
      frame.on('loaded', () => setIsOnCall(true));
      frame.on('left-meeting', () => {
        getMeetingInfo();
        setIsOnCall(false);
        endCall();
      });
      frame.on('error', (e) => {
        console.error('Call error:', e);
        setIsOnCall(false);
        endCall();
      });
    }
  };

  const endCall = () => {
    onCallEnded();
  };

  useEffect(() => {
    return () => {
      // Cleanup when the component unmounts
      if (callFrame) {
        callFrame.destroy();
      }
    };
  }, [callFrame]);

  return (
    <div className='video-call-container'>
      {!isCreatingRoom && (
        <button
          onClick={isOnCall ? endCall : startCall}
          className={`navbar__call-btn navbar__call-btn--${isOnCall ? 'active' : 'inactive'}`}
          title={isOnCall ? "Leave call" : "Join call"}
        >
          {isOnCall ? <FaVideoSlash size={14} /> : <FaVideo size={14} />}
          <span>{isOnCall ? "Leave Call" : "Join Call"}</span>
        </button>
      )}

      {/* Compact iframe wrapper */}
      <div className={`iframe-wrapper ${isOnCall && 'active'}`}>
        <iframe
          allow="microphone; camera; autoplay; display-capture"
          title="call iframe"
          ref={iframeRef}
          style={{ width: '100%', height: '100%', border: "none" }}
        ></iframe>
      </div>
    </div>
  );
};

export default VideoCall;