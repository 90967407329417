const REACT_APP_DAILYJS_API_KEY = process.env.REACT_APP_DAILYJS_API_KEY;

export const createMeeting = async (roomName) => {
    try {
        // Step 1: Check if the room exists
        const checkResponse = await fetch(`https://api.daily.co/v1/rooms/${roomName}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${REACT_APP_DAILYJS_API_KEY}`, // Use your API key
            },
        });

        // If room exists, return it
        if (checkResponse.ok) {
            const roomData = await checkResponse.json();
            return roomData; // Room exists, return the room data
        }

        // Step 2: If room doesn't exist, create a new room
        if (checkResponse.status === 404) {
            const createResponse = await fetch('https://api.daily.co/v1/rooms', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${REACT_APP_DAILYJS_API_KEY}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: roomName, // Predefined room name
                }),
            });

            if (!createResponse.ok) {
                console.error('Error creating meeting:', createResponse.statusText);
                return;
            }

            const createData = await createResponse.json();
            return createData; // Return the created room data (contains URL, etc.)
        }

        // If something goes wrong, handle it here
        throw new Error('Unexpected error occurred');

    } catch (error) {
        console.error('Error during operation:', error);
    }
};
