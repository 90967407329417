import React, { createContext, useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { getAuthToken } from '../services/api';
const SocketContext = createContext();
const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_URL;


export const SocketProvider = ({ user, children }) => {
  const [socket, setSocket] = useState(null);

  const connect = async () => {
    try {
      const authToken = await getAuthToken();
      const newSocket = io(SOCKET_SERVER_URL, {
        autoConnect: true,
        reconnection: true,
        reconnectionAttempts: Infinity, // Keep trying forever
        reconnectionDelay: 1000, // Start with 1 second delay
        reconnectionDelayMax: 5000, // Maximum delay of 5 seconds
        randomizationFactor: 0.5, // Add some randomization to prevent thundering herd
        timeout: 20000, // Timeout after 20 seconds
        extraHeaders: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      newSocket.on('connect_error', (error) => {
        console.log('Connection error, attempting reconnect...', error);
      });

      newSocket.on('reconnect_attempt', (attemptNumber) => {
        console.log(`Reconnection attempt #${attemptNumber}...`);
      });

      setSocket(newSocket);
    } catch (error) {
      console.error("Error connecting to socket:", error);
      // Retry connection after 1 second
      setTimeout(connect, 1000);
    }
  };

  useEffect(() => {
    if (user) {
      connect();
    } else {
      socket?.disconnect();
      setSocket(null);
    }

    return () => {
      socket?.disconnect();
    };
  }, [user]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);
