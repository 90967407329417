import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { auth, provider, signInWithPopup } from "./../../services/firebase.js";
import { FcGoogle } from "react-icons/fc";
import { FaCode } from "react-icons/fa";
import "./Login.scss";

const CodingIllustration1 = () => (
    <svg className="illustration coding-left" viewBox="0 0 1031 815" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M435.5 537.5C451.5 542.5 459 559.5 459 567C467.5 574.5 481.5 597.5 485.5 597.5C489.5 597.5 507 587.5 515.5 582.5" stroke="#61DAFB" strokeWidth="4"/>
        <path d="M321.5 428.5C337.5 433.5 345 450.5 345 458C353.5 465.5 367.5 488.5 371.5 488.5C375.5 488.5 393 478.5 401.5 473.5" stroke="#61DAFB" strokeWidth="4"/>
        <rect x="238" y="292" width="180" height="120" rx="8" fill="#2B2B2B" stroke="#61DAFB" strokeWidth="4"/>
        <path d="M298 347L318 367L298 387" stroke="#61DAFB" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M358 347L338 367L358 387" stroke="#61DAFB" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

const CodingIllustration2 = () => (
    <svg className="illustration coding-right" viewBox="0 0 1031 815" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="238" y="292" width="180" height="120" rx="8" fill="#2B2B2B" stroke="#61DAFB" strokeWidth="4"/>
        <path d="M278 347L258 367L278 387" stroke="#61DAFB" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M318 367L378 367" stroke="#61DAFB" strokeWidth="4" strokeLinecap="round"/>
    </svg>
);

const MatrixRain = () => (
    <div className="matrix-rain">
        {Array(15).fill().map((_, i) => (
            <div key={i} className="matrix-column" style={{ animationDelay: `${Math.random() * 2}s` }}>
                {'01'.split('').map((char, index) => (
                    <span key={index} style={{ animationDelay: `${Math.random() * 5}s` }}>{char}</span>
                ))}
            </div>
        ))}
    </div>
);

const CornerAccents = () => (
    <div className="corner-accents">
        <svg className="corner top-left" viewBox="0 0 50 50">
            <path d="M0 50 L0 10 L10 0 L50 0" stroke="#61dafb" strokeWidth="1" fill="none" />
        </svg>
        <svg className="corner bottom-right" viewBox="0 0 50 50">
            <path d="M50 0 L50 40 L40 50 L0 50" stroke="#61dafb" strokeWidth="1" fill="none" />
        </svg>
    </div>
);

const FloatingSnippets = () => (
    <div className="floating-snippets">
        <div className="snippet">{"<code/>"}</div>
        <div className="snippet">{"function()"}</div>
        <div className="snippet">{"{state}"}</div>
    </div>
);

const Login = ({ user }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const location = useLocation();

    const redirectPath = location.state?.from?.pathname || "/";
    const sessionId = redirectPath?.split("/")?.pop();

    const handleLogin = async () => {
        setLoading(true);
        setError("");
        try {
            await signInWithPopup(auth, provider);
            navigate(redirectPath, { replace: true });
        } catch (error) {
            console.error("Error during login:", error);
            setError("Failed to log in. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (user) {
            navigate(redirectPath, { replace: true });
        }
    });

    return (
        <div className="login-container">
            <MatrixRain />
            <CornerAccents />
            <FloatingSnippets />
            <div className="background-illustrations">
                <CodingIllustration1 />
                <CodingIllustration2 />
            </div>
            <div className="login-card">
                <div className="login-header">
                    <FaCode size={28} style={{ color: "#61dafb" }} />
                    <span className="brand-name">9mb editor</span>
                </div>

                <p className="login-description">
                    Sign in with your Google account to access collaborative coding features
                </p>

                {sessionId && (
                    <p className="session-id">
                        Session ID: {sessionId}
                    </p>
                )}

                {error && (
                    <p className="error-message">{error}</p>
                )}

                <button
                    className="login-button"
                    onClick={handleLogin}
                    disabled={loading}
                    aria-label="Login with Google"
                >
                    <FcGoogle size={20} />
                    {loading ? "Authenticating..." : "Continue with Google"}
                </button>
            </div>
        </div>
    );
};

export default Login;