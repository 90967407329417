import React, { useState, useCallback, useEffect } from "react";
import { FaFile, FaFolder, FaFolderOpen, FaPlus, FaPen } from "react-icons/fa";
import "./FileBrowser.scss";

const getFileIcon = (fileName) => {
  const ext = fileName.split('.').pop().toLowerCase();
  switch (ext) {
    case 'js':
    case 'jsx':
      return <FaFile style={{ color: '#f7df1e' }} />;  // JavaScript yellow
    case 'ts':
    case 'tsx':
      return <FaFile style={{ color: '#3178c6' }} />;  // TypeScript blue
    case 'css':
      return <FaFile style={{ color: '#264de4' }} />;  // CSS blue
    case 'scss':
      return <FaFile style={{ color: '#cc6699' }} />;  // SCSS pink
    case 'html':
      return <FaFile style={{ color: '#e34c26' }} />;  // HTML orange
    case 'json':
      return <FaFile style={{ color: '#5498d7' }} />;  // JSON light blue
    case 'md':
      return <FaFile style={{ color: '#ffffff' }} />;  // Markdown white
    default:
      return <FaFile style={{ color: '#c5c5c5' }} />;  // Default grey
  }
};

const FileBrowser = ({ filename, onFileNameChanged }) => {
  const [files, setFiles] = useState([{ id: '1', name: filename, type: 'file' }]);
  const [editingId, setEditingId] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [newItemType, setNewItemType] = useState('file');
  const [newFileName, setNewFileName] = useState('');
  const [selectedId, setSelectedId] = useState('1');
  const [expandedFolders, setExpandedFolders] = useState(new Set());
  const [lastClickTime, setLastClickTime] = useState(0);
  const [lastClickedId, setLastClickedId] = useState(null);
  const [contextMenu, setContextMenu] = useState({ show: false, x: 0, y: 0, itemId: null });

  const handleRename = (id, newName) => {
    if (!newName.trim()) return;

    // Check if we're renaming a file in a folder
    const fileToRename = files.find(f => f.id === id);
    if (!fileToRename) return;

    setFiles(files.map(f => {
      if (f.id === id) {
        if (f.type === 'file') {
          onFileNameChanged(newName);
        }
        return { ...f, name: newName };
      }
      return f;
    }));
    setEditingId(null);
    setNewFileName('');
  };

  const handleFileClick = (file) => {
    const now = Date.now();

    // For folders, toggle on single click
    if (file.type === 'folder') {
      toggleFolder(file.id);
    }

    if (lastClickedId === file.id && now - lastClickTime < 300) {
      // Double click detected
      setEditingId(file.id);
      setNewFileName(file.name);
    } else {
      setSelectedId(file.id);
      if (file.type === 'file') {
        onFileNameChanged(file.name);
      }
    }
    setLastClickTime(now);
    setLastClickedId(file.id);
  };

  const toggleFolder = (folderId, e) => {
    if (e) e.stopPropagation();
    setExpandedFolders(prev => {
      const newSet = new Set(prev);
      if (newSet.has(folderId)) {
        newSet.delete(folderId);
      } else {
        newSet.add(folderId);
      }
      return newSet;
    });
  };

  const handleCreateNew = () => {
    if (!newFileName.trim()) return;

    // Get parent folder id
    const parentId = selectedId && files.find(f => f.id === selectedId)?.type === 'folder'
      ? selectedId
      : null;

    const newFile = {
      id: Date.now().toString(),
      name: newFileName,
      type: newItemType,
      parentId
    };

    setFiles([...files, newFile]);

    // If creating inside a folder, make sure it's expanded
    if (parentId) {
      setExpandedFolders(prev => new Set([...prev, parentId]));
    }

    // If creating a folder, expand it
    if (newItemType === 'folder') {
      setExpandedFolders(prev => new Set([...prev, newFile.id]));
    }

    if (newItemType === 'file') {
      onFileNameChanged(newFileName);
      setSelectedId(newFile.id);
    }

    setNewFileName('');
    setIsCreating(false);
  };

  const handleContextMenu = useCallback((e, file) => {
    e.preventDefault();
    setContextMenu({
      show: true,
      x: e.pageX,
      y: e.pageY,
      itemId: file.id,
      isFolder: file.type === 'folder'
    });
  }, []);

  const handleDeleteFile = (id) => {
    const fileToDelete = files.find(f => f.id === id);
    if (!fileToDelete) return;

    // If deleting a folder, also delete all children
    const idsToDelete = new Set([id]);
    if (fileToDelete.type === 'folder') {
      const findChildren = (parentId) => {
        files.forEach(f => {
          if (f.parentId === parentId) {
            idsToDelete.add(f.id);
            if (f.type === 'folder') findChildren(f.id);
          }
        });
      };
      findChildren(id);
    }

    setFiles(files.filter(f => !idsToDelete.has(f.id)));
    setContextMenu({ show: false, x: 0, y: 0, itemId: null });
  };

  const FileItem = ({ file, depth = 0 }) => (
    <>
      <div
        className={`file-item ${selectedId === file.id ? 'selected' : ''}`}
        style={{ paddingLeft: `${depth * 16 + 8}px` }}
        onContextMenu={(e) => handleContextMenu(e, file)}
      >
        {editingId === file.id ? (
          <input
            type="text"
            value={newFileName}
            onChange={(e) => setNewFileName(e.target.value)}
            onBlur={() => handleRename(file.id, newFileName)}
            onKeyPress={(e) => e.key === 'Enter' && handleRename(file.id, newFileName)}
            autoFocus
            onClick={(e) => e.stopPropagation()}
          />
        ) : (
          <div className="file-content" onClick={() => handleFileClick(file)}>
            <span className="file-icon">
              {file.type === 'file' ? (
                getFileIcon(file.name)
              ) : expandedFolders.has(file.id) ? (
                <FaFolderOpen style={{ color: '#dcb67a' }} />
              ) : (
                <FaFolder style={{ color: '#dcb67a' }} />
              )}
            </span>
            <span className="file-name">{file.name}</span>
            <div className="file-actions">
              {file.type === 'folder' && (
                <>
                  <button
                    className="action-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedId(file.id);
                      setIsCreating(true);
                      setNewItemType('file');
                      setNewFileName('');
                    }}
                  >
                    <FaFile size={10} style={{ color: '#89d0ff' }} />
                  </button>
                  <button
                    className="action-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedId(file.id);
                      setIsCreating(true);
                      setNewItemType('folder');
                      setNewFileName('');
                    }}
                  >
                    <FaFolder size={10} style={{ color: '#dcb67a' }} />
                  </button>
                </>
              )}
              <button
                className="rename-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  setEditingId(file.id);
                  setNewFileName(file.name);
                }}
              >
                <FaPen size={12} style={{ color: '#89d0ff' }} />
              </button>
            </div>
          </div>
        )}
      </div>
      {file.type === 'folder' && expandedFolders.has(file.id) && (
        <div className="folder-contents">
          {files
            .filter(f => f.parentId === file.id)
            .map(childFile => (
              <FileItem
                key={childFile.id}
                file={childFile}
                depth={depth + 1}
              />
            ))}
        </div>
      )}
    </>
  );

  const ContextMenu = () => {
    if (!contextMenu.show) return null;

    return (
      <div
        className="context-menu"
        style={{ top: contextMenu.y, left: contextMenu.x }}
      >
        <div onClick={() => {
          setEditingId(contextMenu.itemId);
          setNewFileName(files.find(f => f.id === contextMenu.itemId)?.name || '');
          setContextMenu({ show: false, x: 0, y: 0, itemId: null });
        }}>
          Rename
        </div>
        <div onClick={() => handleDeleteFile(contextMenu.itemId)}>
          Delete
        </div>
        {contextMenu.isFolder && (
          <>
            <div className="context-menu-divider" />
            <div onClick={() => {
              setSelectedId(contextMenu.itemId);
              setIsCreating(true);
              setNewItemType('file');
              setNewFileName('');
              setContextMenu({ show: false, x: 0, y: 0, itemId: null });
            }}>
              New File
            </div>
            <div onClick={() => {
              setSelectedId(contextMenu.itemId);
              setIsCreating(true);
              setNewItemType('folder');
              setNewFileName('');
              setContextMenu({ show: false, x: 0, y: 0, itemId: null });
            }}>
              New Folder
            </div>
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    const handleClick = () => setContextMenu({ show: false, x: 0, y: 0, itemId: null });
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  return (
    <div className="file-browser">
      <div className="file-browser-header">
        <span>Explorer</span>
        <div className="header-actions">
          <button onClick={() => {
            setIsCreating(true);
            setNewItemType('file');
            setNewFileName('');
          }}>
            <FaFile size={12} style={{ color: '#89d0ff' }} />
          </button>
          <button onClick={() => {
            setIsCreating(true);
            setNewItemType('folder');
            setNewFileName('');
          }}>
            <FaFolder size={12} style={{ color: '#dcb67a' }} />
          </button>
        </div>
      </div>

      {isCreating && (
        <div className="new-file-input">
          <input
            type="text"
            placeholder={`New ${newItemType} name`}
            value={newFileName}
            onChange={(e) => setNewFileName(e.target.value)}
            onBlur={handleCreateNew}
            onKeyPress={(e) => e.key === 'Enter' && handleCreateNew()}
            autoFocus
          />
        </div>
      )}

      <div className="files-list">
        {files
          .filter(file => !file.parentId)
          .map(file => (
            <FileItem key={file.id} file={file} />
          ))}
      </div>
      <ContextMenu />
    </div>
  );
};

export default FileBrowser;