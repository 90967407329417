import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import compilerService from './../../services/api';
import Editor from '../editor/Editor';
import Output from '../output/Output';
import './FullEditor.scss';  // Import the SCSS
import { useSocket } from './../../context/Socket.IO.Context';
import { generateCartoonHeroName } from '../../utils/randomizer.util';
import { v4 as uuidv4 } from 'uuid';
import { debounce } from 'lodash';
import Navbar from '../navbar/Navbar';
import { Alert, Button } from 'react-bootstrap';
import SplitPane from 'react-split-pane';

export default function FullEditor({ user }) {
  const { sessionId } = useParams(); // Extract sessionId from URL
  const [code, setCode] = useState('');
  const [language, setLanguage] = useState('javascript');
  const [output, setOutput] = useState('');
  const [loading, setLoading] = useState(false);
  const [cursors, setCursors] = useState({});
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const [saving, setSaving] = useState(false); // Track saving state
  const [users, setUsers] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [filename, setFilename] = useState('index.js');

  const leftColumnRef = useRef();
  const rightColumnRef = useRef();
  const socket = useSocket();
  const navigate = useNavigate();
  // Generate a random username and session ID
  const [username] = useState(() => user?.displayName || generateCartoonHeroName());
  const [sessionIdGenerated] = useState(() => user?.uid || uuidv4());

  // Get session data
  const [sessionData, setSessionData] = useState(null);

  useEffect(() => {
    if (sessionId) {
      // Fetch session data from the backend API
      fetchSessionData(sessionId);
    }
  }, [sessionId]);

  const fetchSessionData = async (id) => {
    try {
      setFetching(true);
      const response = await compilerService.getSession(id);
      if (response) {
        setCode(response.content);
        setLanguage(response.language);
        setFilename(response.filename);
        setSessionData(response);
      }
    } catch (error) {
      console.error('Error fetching session:', error);
    } finally {
      setFetching(false);
    }
  };

  const updateSession = async (id, language, content, filename) => {
    try {
      const response = await compilerService.updateSession(id, { language, content, filename });
      setSessionData(response);
    } catch (error) {
      console.error('Error updating session:', error);
    }
  };

  const streamCode = debounce((changedCode, language, filename, username, sessionIdGenerated, cursorPosition) => {
    const data = {
      code: changedCode || '', language, username, filename,
      userId: sessionIdGenerated,
      cursor: {
        content: `${username} is typing...`,
        position: cursorPosition
      }
    };
    // Emit with a callback for acknowledgment
    socket.emit(sessionId, data);
  }, 500); // Debounce the function to avoid sending too many events

  const onChange = (changedCode, cursorPosition) => {
    if (!sessionId || !sessionIdGenerated) return;
    if (changedCode === code) return;
    setCode(changedCode || '');
    streamCode(changedCode, language, filename, username, sessionIdGenerated, cursorPosition);
  };

  const sendCodeToExecute = () => {
    executeCode(code, language, filename);
  };

  const clearOutput = () => {
    setOutput('');
  };

  const saveCodeToDatabase = async () => {
    if (sessionId && language && code && filename) {
      setSaving(true); // Start saving
      await updateSession(sessionId, language, code, filename); // Save the session
      setSaving(false); // End saving
    }
  };

  // Add this function before the useEffect that handles socket connections
  const joinRoom = (roomId) => {
    if (socket && roomId) {
      const userData = {
        userId: sessionIdGenerated,
        username: username,
      };
      socket.emit('joinRoom', roomId, userData);
    }
  };

  useEffect(() => {
    // Connect socket when component mounts
    if (socket && sessionId) {
      if (!socket.connected) {
        socket.connect();
      }
      setIsSocketConnected(true);
      joinRoom(sessionId);
    }

    // Setup event listeners
    socket && socket.on('connect', () => {
      console.log('Socket connected');
      setIsSocketConnected(true);
      sessionId && joinRoom(sessionId);
    });
    
    socket && socket.on('disconnect', () => {
      console.log('Socket disconnected, attempting reconnect...');
      setIsSocketConnected(false);
      // Attempt to reconnect if we're still in the editor
      if (socket && sessionId) {
        socket.connect();
      }
    });

    // Add reconnect event listener
    socket && socket.on('reconnect', (attemptNumber) => {
      console.log(`Reconnected after ${attemptNumber} attempts`);
      setIsSocketConnected(true);
      sessionId && joinRoom(sessionId);
    });

    // get the code change events from the server
    socket && sessionId && socket.on(sessionId, (data) => {
      const channel = data.channel;
      const remotelanguage = data.language;
      const content = data.content;
      const socketFilename = data.filename;
      const senderSocketId = data.senderSocketId;
      const cursor = data.cursor;
      const userId = data.userId;
      if (userId && sessionIdGenerated && channel === sessionId && senderSocketId !== socket.id && userId !== sessionIdGenerated) {
        // Update the code only if it is different from the current code or empty
        if (content !== code || content === '') setCode(content);
        if (remotelanguage !== language) setLanguage(language);
        if (socketFilename !== filename) setFilename(socketFilename);
        if (cursor) {
          setCursors((previousCursors) => {
            const newCursors = { ...previousCursors };
            newCursors[senderSocketId] = data.cursor;
            newCursors[senderSocketId].username = data.username;
            return newCursors;
          });
        }
      }
    });

    // get the output events from the server
    socket && sessionId && socket.on('output', (data) => {
      const { output, sessionId: sid } = data;
      if (sid === sessionId) {
        setOutputText(output);
      }
    });

    // get the command events from the server
    socket && sessionId && socket.on('command', (data) => {
      const { command, sessionId: sid } = data;
      if (sid === sessionId) {
        switch (command) {
          case 'start':
            setOutput('');
            setLoading(true);
            break;
          case 'end':
            setLoading(false);
            break;
          default:
            console.log('Unknown command:', command);
        }
      }
    });

    // get the users list from socket events
    socket && sessionId && socket.on('users', (data) => {
      const { users, sessionId: sid } = data;
      if (sid === sessionId) {
        // remove the current user from the list
        setUsers(users.filter((u) => u.userId !== sessionIdGenerated));
      }
    });

    // on filename changed
    socket && sessionId && socket.on("changeFilename", (data) => {
      const changedFilename = data.filename || "";
      const senderSocketId = data.senderSocketId;
      if (socket.id !== senderSocketId) {
        setFilename(changedFilename);
      }
    });

    // on language changed
    socket && sessionId && socket.on("changeLanguage", (data) => {
      const changedLanguage = data.language || "";
      const id = data.id;
      if (id !== sessionIdGenerated) {
        setLanguage(changedLanguage);
      }
    });

    return () => {
      // Cleanup when component unmounts
      if (socket) {
        socket.disconnect();
        socket.off(sessionId);
        socket.off('output');
        socket.off('command');
        socket.off('users');
        socket.off('changeFilename');
        socket.off('changeLanguage');
        socket.off('connect');
        socket.off('disconnect');
        socket.off('reconnect');
        setIsSocketConnected(false);
      }
    };
  }, [socket, sessionId, sessionIdGenerated, code, language]);

  function setOutputText(output) {
    setOutput((previousOutput) => {
      // Trim the previous output to remove trailing whitespace
      const trimmedPreviousOutput = previousOutput.trimEnd();

      // Process the new output:
      // 1. Split into lines
      // 2. Trim each line to remove leading/trailing spaces
      // 3. Filter out empty lines
      // 4. Join the lines back with newline characters
      const processedOutput = output
        .split('\n') // Split into lines
        .map((line) => line.trim()) // Trim each line
        .filter((line) => line !== '') // Filter out empty lines
        .join('\n'); // Join lines with newline characters

      // Combine the previous output with the new processed output
      return trimmedPreviousOutput
        ? `${trimmedPreviousOutput}\n${processedOutput}`
        : processedOutput;
    });
  }

  // Execute the code
  async function executeCode(codeString, selectedLanguage, filename) {
    if (codeString && selectedLanguage && sessionId && filename) {
      setLoading(true);
      const response = await compilerService.runCode({ code: codeString, language: selectedLanguage?.toLowerCase(), filename, sessionId });
      setOutputText(response);
    }
  }

  // Handle the Share Button functionality
  const shareSession = () => {
    const shareLink = `${window.location.href}`;
    navigator.clipboard.writeText(shareLink)
      .then(() => {
        alert('Session link copied to clipboard!');
      })
      .catch((error) => {
        console.error('Error copying to clipboard:', error);
        alert('Failed to copy link.');
      });
  };

  // Debounced functions to ensure `socket.emit` is called once after 1 second of inactivity
  const debouncedEmitFilename = debounce((sessionId, filename) => {
    socket.emit(sessionId, { filename });
  }, 500);

  const debouncedEmitLanguage = debounce((sessionId, language) => {
    socket.emit(sessionId, { language });
  }, 500);

  // Triggered when the file name is changed
  const onFileNameChange = (changedFilename) => {
    const newFilename = changedFilename || "Untitled File"; // Fallback to "Untitled File"
    setFilename(newFilename); // Update the state
    debouncedEmitFilename(sessionId, newFilename); // Emit with debounce
  };

  // Triggered when the language is changed
  const setSelectedLanguage = (changedLanguage) => {
    setLanguage(changedLanguage); // Update the state
    debouncedEmitLanguage(sessionId, changedLanguage); // Emit with debounce
  };


  return (
    <div className="full-editor">
      {!fetching && !sessionData && <Alert show={true} variant="warning">
        <Alert.Heading>Session not found</Alert.Heading>
        <p>
          {
            `The session with ID ${sessionId} was not found.`
          }
        </p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button onClick={() => navigate('/')} variant="outline-success">
            Create a new session
          </Button>
        </div>
      </Alert>}
      {sessionData && (
        <>
          <Navbar
            sessionId={sessionId}
            isEditor={true}
            isSocketConnected={isSocketConnected}
            user={user}
            users={users}
            sessionOwner={sessionData?.userId}
            handleSave={sendCodeToExecute}
            onRun={sendCodeToExecute}
            onSave={saveCodeToDatabase}
            onErase={clearOutput}
            onShare={shareSession}
            executing={loading}
            saving={saving} // Use the saving state here
          />
          <div className="editor-content">
            <SplitPane
              split="vertical"
              minSize={530} // Update minimum size to account for sidebar (50px) + file browser (180px) + minimum editor width (300px)
              maxSize={-200} // Minimum output width (from right)
              defaultSize="70%" // Initial split
              style={{ height: 'calc(100vh - 56px)' }} // Add this line
              primary="first"
              onChange={(size) => {
                // Force Monaco editor to update its layout
                if (window.editor) {
                  window.requestAnimationFrame(() => {
                    window.editor.layout();
                  });
                }
              }}
            >
              <div className="col-editor">
                <div className="editor-container">
                  <Editor
                    onChange={onChange}
                    setSelectedLanguage={setSelectedLanguage}
                    code={code}
                    language={language}
                    cursors={cursors}
                    filename={filename}
                    onFileNameChanged={onFileNameChange}
                    onMount={(editor) => {
                      window.editor = editor;
                    }}
                  />
                </div>
              </div>
              <div className="col-output">
                <div className="output-container">
                  <Output output={output} />
                </div>
              </div>
            </SplitPane>
          </div>
        </>
      )}
    </div>
  );
}