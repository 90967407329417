import React from "react";
import { languages } from "../../utils/editor.util";

const LanguageSidebar = ({ language, handleLanguageSelect }) => {
    return (
        <div
            style={{
                width: "48px",
                backgroundColor: "#1a1f2c",
                color: "white",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "12px 0",
                boxShadow: "2px 0 15px rgba(0, 0, 0, 0.2)",
                borderRight: "1px solid rgba(255, 255, 255, 0.05)"
            }}
        >
            {languages.map(({ lang, Icon }, index) => {
                const isActive = language?.toLowerCase() === lang;
                return (
                    <div
                        key={index}
                        onClick={() => handleLanguageSelect(lang)}
                        style={{
                            width: "36px",
                            height: "36px",
                            margin: "4px 0",
                            backgroundColor: isActive ? "#3498db" : "transparent",
                            borderRadius: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            position: "relative",
                            transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
                            transform: isActive ? "scale(1.1)" : "scale(1)",
                            boxShadow: isActive 
                                ? "0 4px 12px rgba(52, 152, 219, 0.3)" 
                                : "none",
                            "&:hover": {
                                backgroundColor: isActive ? "#3498db" : "rgba(255, 255, 255, 0.1)",
                                transform: "scale(1.1)",
                            }
                        }}
                    >
                        <Icon
                            size={22}
                            style={{
                                color: isActive ? "#ffffff" : "#8395a7",
                                transition: "color 0.3s ease",
                                filter: isActive ? "drop-shadow(0 2px 4px rgba(255, 255, 255, 0.2))" : "none"
                            }}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default LanguageSidebar;