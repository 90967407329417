import { useState, useEffect, useRef } from "react";
import { FaCode, FaPlay, FaCog, FaVideo, FaVideoSlash } from "react-icons/fa"; // Remove FaSave
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap CSS is imported
import TopBarProgress from "react-topbar-progress-indicator";
import "./Navbar.scss";
import { handleSignOut } from "../../services/firebase";
import VideoCall from "../videoCall/VideoCall";

TopBarProgress.config({
    barColors: {
        "0": "#00bcd4", // Bright cyan
        "0.5": "#ff4081", // Bright pink
        "1.0": "#ffeb3b", // Bright yellow
    },
    shadowBlur: 5,
    shadowColor: "rgba(255, 255, 255, 0.3)", // Light shadow for contrast
});

const Navbar = ({
    sessionId,
    isEditor = false,
    onRun,
    executing = false,
    // Remove onSave, saving, handleSave props
    sessionOwner,
    user,
    users = [], // Changed from connectedUsers to users and default to empty array
    filename = "index.js",
    onFileNameChange,
    onJoinCall = () => { },
    isInCall = false,
    isSocketConnected = false, // Add this prop for connection status
    onLogout = () => { } // Add this prop
}) => {
    // Remove isCodeOwner const as it's only used for save functionality
    const [isEditing, setIsEditing] = useState(false);
    const inputRef = useRef(null);
    const navigate = useNavigate();
    const [videoCallKey, setVideoCallKey] = useState(1);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.style.width = `${Math.max(inputRef.current.scrollWidth, 70)}px`;
        }
    }, [filename]);

    const handleFileNameChange = (e) => {
        onFileNameChange(e.target.value);
    };

    const handleFileNameBlur = () => {
        setIsEditing(false);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleFileNameBlur();
        }
    };

    const onCallEnded = () => {
        setVideoCallKey((previousKey) => previousKey + 1);
    };

    const handleLogout = async () => {
        const result = await handleSignOut();
        if (result.success) {
            navigate('/login');
        }
    };

    // add the current user to the list of users
    const currentUser = {
        userId: user?.uid,
        username: user?.displayName,
        online: isSocketConnected
    };

    const onlineUsers = [...users.map((u) => ({
        ...u,
        isCurrentUser: u.userId === user?.uid,
        online: u.userId === user?.uid ? isSocketConnected : u.online
    })), currentUser]; // Map users to allUsers

    console.log(onlineUsers);
    console.log(sessionOwner);


    const otherUsersOnline = users.length > 0;

    return (
        <>
            <div className="navbar">
                <div
                    className="navbar__logo"
                    onClick={() => navigate("/")}
                    onMouseEnter={(e) => {
                        e.currentTarget.style.transform = "translateY(-1px)";
                    }}
                    onMouseLeave={(e) => {
                        e.currentTarget.style.transform = "translateY(0)";
                    }}
                >
                    <FaCode
                        size={28} // Increased size to match SCSS
                        className="navbar__logo-icon"
                    />
                    <span className="navbar__logo-text">
                        9mb editor
                    </span>
                </div>

                {isEditor ? (
                    <div className="navbar__actions">
                        <button
                            className={`navbar__run-btn ${executing ? 'navbar__run-btn--executing' : ''}`}
                            onClick={() => !executing && onRun()}
                            disabled={executing}
                        >
                            {!executing && <FaPlay size={14} />}
                            {executing && <FaCog size={14} className="spin" />}
                            <span>RUN</span>
                        </button>

                        {onlineUsers.map((connectedUser) => (
                            <div
                                key={connectedUser.userId}
                                className={`navbar__user ${connectedUser.isCurrentUser ? 'navbar__user--current' : ''}`}
                                title={`${connectedUser.username} ${connectedUser.userId === sessionOwner ? '(Owner)' : ''} ${connectedUser.isCurrentUser ? '(You)' : ''}`}
                            >
                                <div className={`navbar__user-status navbar__user-status--${connectedUser.online ? 'online' : 'offline'}`} />
                                <span>{connectedUser.username?.split(' ')[0]}</span>
                                {connectedUser.userId === sessionOwner && (
                                    <span className="navbar__user-crown">👑</span>
                                )}
                            </div>
                        ))}

                        {otherUsersOnline && (
                            <VideoCall
                                key={videoCallKey}
                                onCallEnded={onCallEnded}
                                sessionId={sessionId}
                                username={user.displayName}
                                userId={user.uid}
                            />
                        )}
                    </div>
                ) : (
                    <div className="navbar__actions">
                        {user ? (
                            <>
                                <div className="navbar__user navbar__user--current">
                                    <div className="navbar__user-status navbar__user-status--online" />
                                    <span>{user.displayName || 'Anonymous'}</span>
                                </div>
                                <button
                                    className="navbar__logout-btn"
                                    onClick={handleLogout}
                                >
                                    Logout
                                </button>
                            </>
                        ) : (
                            <button
                                className="navbar__login-btn"
                                onClick={() => navigate("/login")}
                            >
                                Login
                            </button>
                        )}
                    </div>
                )}
            </div>
            <div>{executing && <TopBarProgress />}</div> {/* Remove saving from condition */}
        </>
    );
};

export default Navbar;