import React, { useEffect, useRef, useState } from "react";
import Editor from "@monaco-editor/react";
import { generateConsistentColor } from "../../utils/randomizer.util";
import LanguageSidebar from "./../languageSidebar/LanguageSidebar";
import FileBrowser from "./../fileBrowser/FileBrowser";
import "./Editor.scss";

const MonacoEditorWithSidebarAndHeader = ({
  onChange,
  language = "javascript",
  code,
  cursors = [],
  setSelectedLanguage,
  filename,
  onFileNameChanged,
  onMount,
}) => {
  const editorRef = useRef(null);
  const monacoRef = useRef(null);
  const [visibleTooltips, setVisibleTooltips] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [activeView, setActiveView] = useState('explorer');

  const handleEditorDidMount = (editor, monaco) => {
    editorRef.current = editor;
    monacoRef.current = monaco;
    monaco.editor.setTheme("vs-dark");
    if (onMount) onMount(editor);
  };

  const onCodeChanged = (changedCode) => {
    const editor = editorRef.current;
    const position = editor.getPosition();
    onChange(changedCode, position);
  };

  const calculateTooltipPosition = (cursor) => {
    const username = cursor.username;
    const position = cursor.position;
    if (!editorRef.current) return null;
    const editor = editorRef.current;
    const domNode = editor.getDomNode();
    const cursorCoords = editor.getScrolledVisiblePosition({
      lineNumber: position.lineNumber,
      column: position.column,
    });

    if (domNode && cursorCoords) {
      return {
        top: cursorCoords.top + domNode.offsetTop,
        left: cursorCoords.left + domNode.offsetLeft + 10,
        tooltip: username,
      };
    }
    return null;
  };

  useEffect(() => {
    const updatedTooltips = {};
    Object.keys(cursors).forEach((cursor) => {
      updatedTooltips[cursor] = true;
      setTimeout(() => {
        setVisibleTooltips((prev) => {
          const newTooltips = { ...prev };
          delete newTooltips[cursor];
          return newTooltips;
        });
      }, 2000);
    });
    setVisibleTooltips(updatedTooltips);
  }, [cursors]);

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
  };

  const handleFileSelect = (file) => {
    setSelectedFile(file);
    const fileContent = `// Content of ${file.name}\nconsole.log("Hello, ${file.name}!");`;
    onChange(fileContent);
  };

  const renderSideContent = () => {
    switch (activeView) {
      case 'explorer':
        return (
          <FileBrowser
            filename={filename}
            code={code}
            onFileNameChanged={onFileNameChanged}
            onFileSelect={handleFileSelect}
          />
        );
      case 'search':
        return <div className="side-panel">Search (Coming Soon)</div>;
      case 'git':
        return <div className="side-panel">Source Control (Coming Soon)</div>;
      case 'debug':
        return <div className="side-panel">Debug (Coming Soon)</div>;
      case 'extensions':
        return <div className="side-panel">Extensions (Coming Soon)</div>;
      default:
        return null;
    }
  };

  return (
    <div className="editor-workspace">
      <LanguageSidebar
        language={language}
        handleLanguageSelect={handleLanguageSelect}
      />
      {renderSideContent()}
      <div className="editor-container">
        <Editor
          key={language} // Add this line to force re-render on language change
          height="100%" // This will now work properly with the container
          defaultLanguage={language?.toLowerCase()}
          value={code}
          onChange={onCodeChanged}
          onMount={handleEditorDidMount}
          options={{
            fontSize: 14,
            fontFamily: "'JetBrains Mono', 'Fira Code', 'Consolas', monospace",
            fontLigatures: true,
            lineHeight: 1.6,
            letterSpacing: 0.5,
            lineNumbers: "on",
            minimap: { enabled: false },
            scrollBeyondLastLine: false,
            wordWrap: "on",
            theme: "vs-dark",
            renderIndentGuides: true,
            guides: { indentation: true },
            padding: { top: 10 },
            smoothScrolling: true,
            cursorBlinking: "smooth",
            cursorSmoothCaretAnimation: true,
            scrollbar: {
              vertical: 'visible',
              horizontal: 'visible',
              useShadows: true,
              verticalScrollbarSize: 10,
              horizontalScrollbarSize: 10
            }
          }}
        />

        {Object.keys(cursors).map((cursor, index) => {
          const content = cursors[cursor];
          const position = calculateTooltipPosition(content);
          return position && visibleTooltips[cursor] && (
            <div
              key={index}
              className="cursor-tooltip"
              style={{
                top: position.top,
                left: position.left,
                backgroundColor: generateConsistentColor(content.username),
              }}
            >
              {position.tooltip}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MonacoEditorWithSidebarAndHeader;