import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Table, Spinner, Alert, Badge, Modal, Form, Pagination } from 'react-bootstrap';
import { formatDistanceToNow } from 'date-fns'; // For date formatting
import compilerService from '../../services/api'; // Your API service
import './Dashboard.scss'; // Optional: Add styles
import { languages } from '../../utils/editor.util';
import Navbar from './../navbar/Navbar'; // Import the Navbar component

const Dashboard = ({ user }) => {
  const [sessions, setSessions] = useState([]); // State to store sessions
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [showEditModal, setShowEditModal] = useState(false); // Edit modal visibility
  const [selectedSession, setSelectedSession] = useState(null); // Session being edited
  const [showCreateModal, setShowCreateModal] = useState(false); // Create modal visibility
  const [newSession, setNewSession] = useState({
    title: '',
    description: '',
    language: 'javascript', // Default language
  });
  const [validationErrors, setValidationErrors] = useState({
    title: '',
    language: '',
  });
  const [currentPage, setCurrentPage] = useState(1); // Current page for pagination
  const [totalCount, setTotalCount] = useState(0); // Total number of sessions
  const sessionsPerPage = 5; // Number of sessions per page
  const [searchTerm, setSearchTerm] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [sessionToDelete, setSessionToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const navigate = useNavigate();

  // Fetch sessions from the backend when the component mounts or the page changes
  useEffect(() => {
    fetchSessions(currentPage);
  }, [currentPage]);

  // Function to fetch sessions with pagination
  const fetchSessions = async (page) => {
    try {
      setIsFetching(true);
      const response = await compilerService.getSessions({
        page,
        limit: sessionsPerPage,
      });
      setSessions(response.sessions); // Set the sessions for the current page
      setTotalCount(response.totalCount); // Set the total number of sessions
    } catch (err) {
      setError('Failed to fetch sessions. Please try again later.');
      console.error('Error fetching sessions:', err);
    } finally {
      setIsFetching(false);
    }
  };

  // Open the create session modal
  const openCreateModal = () => {
    setShowCreateModal(true);
    setValidationErrors({ title: '', language: '' }); // Reset validation errors
  };

  // Close the create session modal
  const closeCreateModal = () => {
    setShowCreateModal(false);
    setNewSession({ title: '', description: '', language: 'javascript' }); // Reset form
  };

  // Handle changes in the create session form
  const handleCreateChange = (e) => {
    const { name, value } = e.target;
    setNewSession((prev) => ({
      ...prev,
      [name]: value,
    }));
    // Clear validation error when the user starts typing
    if (name === 'title' || name === 'language') {
      setValidationErrors((prev) => ({
        ...prev,
        [name]: '',
      }));
    }
  };

  // Validate the form
  const validateForm = () => {
    const errors = {};
    if (!newSession.title.trim()) {
      errors.title = 'Title is required.';
    }
    if (!newSession.language.trim()) {
      errors.language = 'Language is required.';
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors
  };

  // Function to create a new session
  const createNewSession = async () => {
    if (!validateForm()) {
      return; // Stop if validation fails
    }
    try {
      setIsCreating(true);
      const session = await compilerService.createSession({
        title: newSession.title,
        description: newSession.description,
        language: newSession.language,
        content: '// Start coding here...', // Default content
        filename: 'index.js', // Default filename
      });
      await fetchSessions(currentPage); // Refresh the list after creating a new session
      closeCreateModal();
      navigateToSession(session._id); // Navigate to the new session
    } catch (e) {
      console.error('Error creating session:', e);
      setError('Failed to create session. Please try again later.');
    } finally {
      setIsCreating(false);
    }
  };

  // Function to navigate to a specific session
  const navigateToSession = (sessionId) => {
    navigate(`/editor/${sessionId}`);
  };

  // Format the createdAt timestamp
  const formatDate = (timestamp) => {
    return formatDistanceToNow(new Date(timestamp), { addSuffix: true }); // e.g., "2 hours ago"
  };

  // Open the edit modal for a session
  const openEditModal = (session) => {
    setSelectedSession(session);
    setShowEditModal(true);
  };

  // Close the edit modal
  const closeEditModal = () => {
    setShowEditModal(false);
    setSelectedSession(null);
  };

  // Handle changes in the edit form
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setSelectedSession((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Save the updated session details
  const saveEditedSession = async () => {
    try {
      setIsEditing(true);
      const response = await compilerService.updateSession(selectedSession._id, selectedSession);
      setSessions((prevSessions) =>
        prevSessions.map((session) =>
          session._id === selectedSession._id ? response : session
        )
      );
      fetchSessions(currentPage); // Refresh the list after updating a session
      closeEditModal();
    } catch (err) {
      console.error('Error updating session:', err);
      setError('Failed to update session. Please try again later.');
    } finally {
      setIsEditing(false);
    }
  };

  // Add these new functions
  const openDeleteModal = (session) => {
    setSessionToDelete(session);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setSessionToDelete(null);
  };

  const handleDeleteSession = async () => {
    try {
      setIsDeleting(true);
      await compilerService.deleteSession(sessionToDelete._id);
      await fetchSessions(currentPage);
      closeDeleteModal();
    } catch (err) {
      console.error('Error deleting session:', err);
      setError('Failed to delete session. Please try again later.');
    } finally {
      setIsDeleting(false);
    }
  };

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const filteredSessions = sessions.filter(session => {
    const searchLower = searchTerm.toLowerCase();
    return (
      session?.title?.toLowerCase()?.includes(searchLower) ||
      session?.language?.toLowerCase()?.includes(searchLower) ||
      (session?.description && session?.description.toLowerCase()?.includes(searchLower))
    );
  });

  return (
    <div className="dashboard-container">
      <Navbar user={user} />

      <div className="dashboard-content">
        <div className="content-header">
          <div className="d-flex align-items-center gap-4">
            <div className="d-flex align-items-center">
              <i className="fas fa-terminal text-primary me-2"></i>
              <h4 className="mb-0">Sessions</h4>
            </div>
            <div className="search-container">
              <i className="fas fa-search search-icon"></i>
              <input
                type="text"
                placeholder="Search sessions..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input"
              />
            </div>
          </div>
          <button className="new-session-btn" onClick={openCreateModal}>
            <i className="fas fa-plus-circle"></i>
          </button>
        </div>

        {isFetching ? (
          <div className="loader-wrapper">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div className="sessions-wrapper">
            <div className="table-container">
              <Table borderless>
                <thead>
                  <tr>
                    <th style={{ width: '40%' }}>Project</th>
                    <th style={{ width: '15%' }}>Language</th>
                    <th style={{ width: '25%' }}>Last Modified</th>
                    <th style={{ width: '20%' }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredSessions.map(session => (
                    <tr key={session._id} className="session-row">
                      <td className="title-cell">
                        <span className="session-title">{session.title}</span>
                        {session.description &&
                          <small className="description-text">{session.description}</small>
                        }
                      </td>
                      <td>
                        <span className="lang-badge">{session.language}</span>
                      </td>
                      <td className="date-cell">{formatDate(session.createdAt)}</td>
                      <td className="actions-cell">
                        <div className="action-buttons">
                          <button
                            className="icon-btn"
                            onClick={() => navigateToSession(session._id)}
                            data-tooltip="Open Editor"
                          >
                            <i className="fas fa-code"></i>
                          </button>
                          <button
                            className="icon-btn"
                            onClick={() => openEditModal(session)}
                            data-tooltip="Edit Details"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </button>
                          <button
                            className="icon-btn text-danger"
                            onClick={() => openDeleteModal(session)}
                            data-tooltip="Delete"
                            disabled={isDeleting}
                          >
                            <i className={`fas ${isDeleting ? 'fa-spinner fa-spin' : 'fa-trash-alt'}`}></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {filteredSessions.length === 0 && (
                <div className="no-results">
                  <i className="fas fa-search mb-2"></i>
                  <p>No sessions found matching "{searchTerm}"</p>
                </div>
              )}
            </div>

            <Pagination size="sm" className="custom-pagination">
              {Array.from({ length: Math.ceil(totalCount / sessionsPerPage) }).map(
                (_, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === currentPage}
                    onClick={() => paginate(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                )
              )}
              <Pagination.Prev
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              />
              <Pagination.Next
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === Math.ceil(totalCount / sessionsPerPage)}
              />
            </Pagination>
          </div>
        )}

        <Modal show={showCreateModal} onHide={closeCreateModal} size="sm" centered>
          <Modal.Header className="modal-header-custom">
            <h5 className="mb-0">New Session</h5>
          </Modal.Header>
          <Modal.Body className="p-3">
            <Form.Control
              size="sm"
              placeholder="Session name"
              name="title"
              value={newSession.title}
              onChange={handleCreateChange}
              className="mb-2"
            />
            <Form.Select
              size="sm"
              name="language"
              value={newSession.language}
              onChange={handleCreateChange}
              className="mb-2"
            >
              {languages.map(l => (
                <option key={l.lang} value={l.lang}>{l.lang}</option>
              ))}
            </Form.Select>
            <Form.Control
              size="sm"
              as="textarea"
              rows={2}
              placeholder="Description (optional)"
              name="description"
              value={newSession.description}
              onChange={handleCreateChange}
            />
          </Modal.Body>
          <Modal.Footer className="p-2">
            <Button
              size="sm"
              variant="link"
              className="btn-link"
              onClick={closeCreateModal}
              disabled={isCreating}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              className="create-btn"
              onClick={createNewSession}
              disabled={isCreating}
            >
              {isCreating ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                  Creating...
                </>
              ) : (
                'Create'
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Edit Modal */}
        <Modal show={showEditModal} onHide={closeEditModal} size="sm" centered>
          <Modal.Header className="modal-header-custom">
            <h5 className="mb-0">Edit Session</h5>
          </Modal.Header>
          <Modal.Body className="p-3">
            {selectedSession && (
              <Form>
                <Form.Control
                  size="sm"
                  placeholder="Session Title"
                  name="title"
                  value={selectedSession.title}
                  onChange={handleEditChange}
                  className="mb-2"
                />
                <Form.Control
                  size="sm"
                  as="textarea"
                  rows={2}
                  placeholder="Description (optional)"
                  name="description"
                  value={selectedSession.description}
                  onChange={handleEditChange}
                />
              </Form>
            )}
          </Modal.Body>
          <Modal.Footer className="p-2">
            <Button
              size="sm"
              variant="link"
              className="btn-link"
              onClick={closeEditModal}
              disabled={isEditing}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              className="create-btn"
              onClick={saveEditedSession}
              disabled={isEditing}
            >
              {isEditing ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                  Saving...
                </>
              ) : (
                'Save Changes'
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Add Delete Confirmation Modal */}
        <Modal show={showDeleteModal} onHide={closeDeleteModal} size="sm" centered>
          <Modal.Header className="modal-header-custom">
            <h5 className="mb-0">Delete Session</h5>
          </Modal.Header>
          <Modal.Body className="p-3">
            Are you sure you want to delete "{sessionToDelete?.title}"? This action cannot be undone.
          </Modal.Body>
          <Modal.Footer className="p-2">
            <Button
              size="sm"
              variant="link"
              className="btn-link"
              onClick={closeDeleteModal}
              disabled={isDeleting}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              variant="danger"
              onClick={handleDeleteSession}
              disabled={isDeleting}
            >
              {isDeleting ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                  Deleting...
                </>
              ) : (
                'Delete'
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Dashboard;