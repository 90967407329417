import { useEffect, useRef } from "react";
import "./Output.scss";

const prefix = "usr@local:~$";
const Output = ({ output }) => {
  const outputRef = useRef(null);

  useEffect(() => {
    if (outputRef.current) {
      outputRef.current.scrollTop = outputRef.current.scrollHeight;
    }
  }, [output]);

  const renderLines = () => {
    if (!output) return null;

    return output.split('\n').map((line, index) => (
      <div key={index} className="line">
        <span className="prompt">{prefix}</span>
        <span className="output-text">{line}</span>
      </div>
    ));
  };

  return (
    <div className="terminal">
      <div className="terminal-header">
        <span className="terminal-button close"></span>
        <span className="terminal-button minimize"></span>
        <span className="terminal-button maximize"></span>
      </div>
      <div className="terminal-content" ref={outputRef}>
        {renderLines()}
      </div>
    </div>
  );
};

export default Output;